/*animation element*/
.testimonial-element {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
/*animation element sliding left*/
.testimonial-element.fade-in {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -moz-transition: all 500ms linear;
  -webkit-transition: all 500ms linear;
  -o-transition: all 500ms linear;
  transition: all 500ms linear;
}
.testimonial-element.fade-in.in-view {
  opacity: 1;
  -webkit-font-smoothing: subpixel-antialiased;
}