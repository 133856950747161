

.gauge-container.gauge-container-left {
    right: -105px;
    left: auto;
  }
  .gauge-container.gauge-container-right {
    left: -105px;
    right: auto;
  }

.gauge-container {
    width: 400px;
    height: 200px;
    position: absolute;
    overflow: hidden;
    text-align: center;
    
    transform: translate(-50%, -50%);
  }
  
  .gauge-container-scale 
  {
      transform: scale(0.4);
  }

  .gauge-a {
    z-index: 1;
    position: absolute;
    background-color: rgba(255,255,255,.2);
    width: 400px;
    height: 200px;
    top: 0%;
    border-radius: 250px 250px 0px 0px;
  }
  .gauge-b {
    z-index: 3;
    position: absolute;
    background-color: #222;
    width: 250px;
    height: 125px;
    top: 75px;
    margin-left: 75px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
  }
  .gauge-c {
    z-index: 2;
    position: absolute;
    background-color: #078263;
    width: 400px;
    height: 200px;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0px 0px 200px 200px;
    transform-origin: center top;
    transition: all 1.3s ease-in-out;
  }
  .gauge-container.animation-element.fade-in 
  {
      position:absolute;
  }

   .gauge-container.in-view .gauge-191 
   {  
      transform:rotate(.4775turn);
   }

   .gauge-container.in-view .gauge-33 
   {  
      transform:rotate(.0825turn);
   }

  .gauge-container.in-view .gauge-data { color: rgba(255,255,255,1); }
  .gauge-data {
    z-index: 4;
    color: rgba(255,255,255,.2);
    font-size: 1.5em;
    line-height: 25px;
    position: absolute;
    width: 400px;
    height: 200px;
    top: 90px;
    margin-left: auto;
    margin-right: auto;
    transition: all 1s ease-out;
  }

  .home-research-study
  {
      min-height: 550px;
  }

  
  @media (min-width: 768px) {
    .gauge-container.gauge-container-left {
        right: -40px;
        left: auto;
    }
    .gauge-container.gauge-container-right {
        left: -40px;
        right: auto;
    }
    .gauge-container-scale 
    {
        transform: scale(0.6);
    }

    .home-research-study
    {
        min-height: 650px;
    }
  }

  @media (min-width: 992px) {
    .gauge-container.gauge-container-left {
        right: 0px;
        left: auto;
    }
    .gauge-container.gauge-container-right {
        left: 0px;
        right: auto;
    }
    .gauge-container-scale 
    {
        transform: scale(0.75);
    }

    .home-research-study
    {
        min-height: 650px;
    }
  }

  @media (min-width: 1200px) {
    .gauge-container.gauge-container-left {
        right: 120px;
        left: auto;
      }
    .gauge-container.gauge-container-right {
        left: 120px;
        right: auto;
      }
    .gauge-container-scale 
    {
        transform: scale(0.8);
    }

    .home-research-study
    {
        min-height: 650px;
    }

  }